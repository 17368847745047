<template>
  <b-modal id="servicesModal" centered hide-footer hide-header>
    <!-- Button close -->
    <div class="text-right">
      <b-link class="btn_close" @click="closeModal()">
        <b-icon icon="x" font-scale="2"></b-icon>
      </b-link>
    </div>

    <div class="modal_content text-center p-4">
      <div>
        <h3 class="color-black">LEISTUNGEN</h3>
      </div>

      <div class="mt-4">
        <img
          class="skill_img"
          :src="require('@/assets/website.svg')"
          :alt="website"
        />
        <p class="mt-3">
          Programmierung Ihrer <b>Website</b> mit Vue.js, HTML und CSS
        </p>
      </div>

      <div class="mt-4">
        <img
          class="skill_img"
          :src="require('@/assets/software.svg')"
          :alt="software"
        />
        <p class="mt-3">
          Entwicklung Ihres <b>Softwareprojekts</b> mit Java, Spring Boot und
          Docker
        </p>
      </div>

      <div class="mt-4">
        <img
          class="skill_img"
          :src="require('@/assets/script.svg')"
          :alt="script"
        />
        <p class="mt-3">
          <b>Automatisierung</b> Ihres Linux Systems mit Bash Scripting
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script scoped>
export default {
  methods: {
    closeModal() {
      this.$bvModal.hide("servicesModal");
    },
  },
};
</script>

<style scoped>
.btn_close {
  cursor: pointer;
  padding: 1px;
  text-align: center;
  color: #bf616a;
  margin: auto;
}

.btn_close:hover {
  color: #4c566a;
}

.modal_content {
  color: #4c566a;
}

.skill_img {
  width: 160px;
  border: 0px solid#2e3440;
  filter: drop-shadow(8px 8px 10px #2e3440) opacity(75%) grayscale(25%);
  margin: 0px;
}

h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 40px;
  margin-top: 16px;
}
</style>
