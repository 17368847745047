
<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Moritz Schaal | Software Developer",
    // all titles will be injected into this template
    titleTemplate: "%s",
  },

  mounted() {
    let img = new Image();

    img.onload = () => {
      console.log("img loaded");
      this.imgUrl = img.src;
      this.showImg = true;
    };

    img.src = "../public/projects.svg";

    console.log("mounted");
  },
};
</script>

<style>
/* 
COLORS
*/

.color-foreground {
  color: #4c566a;
}

.color-background {
  background-color: #eceff4;
}

.color-black {
  color: #2e3440;
}

.color-white {
  color: #d8dee9;
}

.color-red {
  color: #bf616a;
}

.color-green {
  color: #a3be8c;
}

.color-yellow {
  color: #ebcb8b;
}

.color-blue {
  color: #81a1c1;
}

.color-magenta {
  color: #b48ead;
}

.color-cyan {
  color: #88c0d0;
}

* {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, Avenir,
    Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
}

html,
body {
  color: #4c566a;
  background-color: #eceff4;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
