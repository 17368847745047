<template>
  <div id="home">
    <!-- Modals -->
    <about-modal />
    <services-modal />
    <references-modal />
    <contact-modal />
    <login-modal />
    <impressum-modal />
    <datenschutz-modal />

    <!-- Header  -->
    <div class="pt-5">
      <img id="avatar_circle" src="@/assets/logo.png" />
      <h1 class="pt-4">Moritz Schaal</h1>
      <h2>Software Developer</h2>
    </div>

    <!-- Content -->
    <div id="content" class="mt-4">
      <div class="p-3">
        <b-link
          class="btn_menu color-green"
          onclick="window.open('https://github.com/murkl')"
        >
          <b-icon icon="github" font-scale="5"></b-icon>
        </b-link>

        <h3 class="mt-2">GitHub</h3>
      </div>

      <div class="p-3">
        <b-link class="btn_menu color-blue" @click="openServicesModal()">
          <b-icon icon="layers" font-scale="5"></b-icon>
        </b-link>
        <h3 class="mt-2">Leistungen</h3>
      </div>

      <div class="p-3">
        <b-link class="btn_menu color-magenta" @click="openReferencesModal()">
          <b-icon icon="clock-history" font-scale="5"></b-icon>
        </b-link>
        <h3 class="mt-2">Referenzen</h3>
      </div>

      <div class="p-3">
        <b-link
          class="btn_menu color-yellow"
          href="mailto:kontakt@moritz-schaal.de"
        >
          <b-icon icon="envelope" font-scale="5"></b-icon>
        </b-link>
        <h3 class="mt-2">Kontakt</h3>
      </div>
    </div>

    <Digital />

    <!-- Footer  -->
    <div id="footer" class="mt-3">
      <div>
        <small>
          <b-link @click="openImpressumModal()">Impressum</b-link>
          <span> • </span>
          <b-link @click="openDatenschutzModal()">Datenschutz</b-link>
        </small>
      </div>
      <div class="mt-4">
        <small>© {{ getCurrentYear() }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import ServicesModal from "@/components/ServicesModal.vue";
import ReferencesModal from "@/components/ReferencesModal.vue";
import ContactModal from "@/components/ContactModal.vue";
import ImpressumModal from "@/components/ImpressumModal.vue";
import DatenschutzModal from "@/components/DatenschutzModal.vue";

import Digital from "@/assets/projects.svg";

export default {
  name: "home",

  components: {
    ServicesModal,
    ReferencesModal,
    ContactModal,
    ImpressumModal,
    DatenschutzModal,
    Digital,
  },

  methods: {
    openServicesModal() {
      this.$bvModal.show("servicesModal");
    },
    openReferencesModal() {
      this.$bvModal.show("referencesModal");
    },
    openContactModal() {
      this.$bvModal.show("contactModal");
    },

    openImpressumModal() {
      this.$bvModal.show("impressumModal");
    },
    openDatenschutzModal() {
      this.$bvModal.show("datenschutzModal");
    },

    showDevToast() {
      this.$bvToast.toast(
        `Diese Funktion ist noch nicht vollständig und befindet sich derzeit im Aufbau.`,
        {
          title: "In Entwicklung",
          toaster: "b-toaster-bottom-center",
          variant: "secondary",
          solid: true,
          autoHideDelay: 7000,
        }
      );
    },

    getCurrentYear() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentYear;
    },
  },
};
</script>

<style scoped>
h1 {
  margin-top: 4pt;
  font-size: 24pt;
  font-weight: bold;
  color: #3b4252;
}

h2 {
  font-size: 12pt;
  font-weight: normal;
  text-transform: uppercase;
  color: #4c566a;
}

h3 {
  font-size: 12pt;
  font-weight: bold;
  padding-top: 8px;
}

#home {
  text-align: center;
  color: #4c566a;
  background-color: #eceff4;
  background-image: url("./../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#bg_container {
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  position: absolute;
  width: 100%;
  max-height: 50%;
  filter: opacity(25%) grayscale(100%);
  height: calc(100vh - 10vh);
}

#content {
  z-index: 5;
}

#status-img-container {
  z-index: 5;
  position: relative;
}

#status-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  padding: 4px;
  filter: opacity(90%);
}

#avatar_circle {
  width: 200px;
  border: 0px solid#2e3440;
  filter: drop-shadow(8px 8px 10px #2e3440) opacity(90%) grayscale(50%);
  margin: 0px;
}

#avatar_circle:hover {
  border: 0px solid#2E3440;
}

#footer {
  padding-top: 12px;
  padding-bottom: 32px;
}

#footer a {
  text-decoration: none;
  color: #4c566a;
}

#footer a:hover {
  text-decoration: underline;
}

.icon_menu {
  text-decoration: none;
  color: #bf616a;
  background-color: transparent;
  width: 24px;
  border: 0px solid#2e3440;
  margin: 0px;
}

.icon_menu:hover {
  text-decoration: none;
  color: #bf616a;
  background-color: transparent;
}

.btn_menu {
  text-decoration: none;
  background-color: transparent;
}

.btn_menu:hover {
  text-decoration: none;
  color: #bf616a;
  background-color: transparent;
}

.menu_link {
  text-decoration: none;
  color: #a3be8c;
}

.menu_link:hover {
  text-decoration: underline;
  color: #bf616a;
}
</style>
