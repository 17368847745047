<template>
  <b-modal id="contactModal" centered hide-footer hide-header>
    <!-- Button close -->

    <div class="text-right">
      <b-link class="btn_close" @click="closeModal()">
        <b-icon icon="x" font-scale="2"></b-icon>
      </b-link>
    </div>

    <div class="modal_content text-center p-4">
      <div>
        <h3 class="color-back">KONTAKT</h3>
        <img class="banner_img" src="@/assets/contact.svg" />

        <div class="p-3">
          <b-link
            class="btn_menu color-yellow"
            href="mailto:mail@moritz-schaal.de"
          >
            <b-icon icon="envelope-fill" font-scale="3"></b-icon>
          </b-link>
          <div class="mt-3"><b>mail@moritz-schaal.de</b></div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script scoped>
export default {
  methods: {
    closeModal() {
      this.$bvModal.hide("contactModal");
    },
  },
};
</script>

<style scoped>
.btn_close {
  cursor: pointer;
  padding: 1px;
  text-align: center;
  color: #bf616a;
  margin: auto;
}

.btn_close:hover {
  color: #4c566a;
}

.modal_content {
  color: #4c566a;
}

.btn_menu {
  text-decoration: none;
  background-color: transparent;
}

.btn_menu:hover {
  text-decoration: none;
  color: #bf616a;
  background-color: transparent;
}

.banner_img {
  width: 180px;
  border: 0px solid#2e3440;
  filter: drop-shadow(8px 8px 10px #2e3440) opacity(70%) grayscale(30%);
  margin: 0px;
}

.container {
  overflow: auto;
  width: 220pt;
}
.mail {
  float: left;
}
.phone {
  float: right;
}
</style>


