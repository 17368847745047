import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import VueMeta from 'vue-meta'

import Digital from '@/assets/projects.svg';

import {
    loadImage
} from './preload';

Vue.use(Router);

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

const router = new Router({
    mode: 'history', // uris without hashes #, see https://router.vuejs.org/guide/essentials/history-mode.html#html5-history-mode
    routes: [{
            path: '/',
            component: Home

        },

        // otherwise redirect to home
        {
            path: '*',
            redirect: '/'
        }
    ]
});

export default router;