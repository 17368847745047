<template>
  <b-modal id="referencesModal" centered hide-header hide-footer>
    <!-- Button close -->
    <div class="text-right">
      <b-link class="btn_close" @click="closeModal()">
        <b-icon icon="x" font-scale="2"></b-icon>
      </b-link>
    </div>

    <div class="modal_content text-center p-4">
      <div>
        <h3 class="color-black">REFERENZEN</h3>
      </div>

      <div class="mt-4">
        <img
          class="banner_img"
          :src="require('@/assets/projects.svg')"
          :alt="projects"
        />
      </div>
    </div>

    <div class="modal_content text-center pl-4 pr-4 pb-4">
      <!-- CARD -->
      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Web Kundenportal</h5>
        <div><small>Sozialversicherung</small></div>

        <div class="p-2">
          <small>
            Agile Entwicklung eines Web Kundenportals in Spring Boot & SAP
            Hybris / Automatisierte Test Entwicklung
          </small>
        </div>

        <button onclick="window.open('https://portal.svlfg.de/svlfg-apps/')">
          Webseite aufrufen
        </button>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Spring Boot</span>
          <span class="tech_item">SAP Hybris</span>
          <span class="tech_item">JSP</span>
          <span class="tech_item">Javascript</span>
          <span class="tech_item">Docker</span>
          <span class="tech_item">Ant</span>
          <span class="tech_item">GitLab</span>
          <span class="tech_item">git</span>
          <span class="tech_item">Scrum</span>
          <span class="tech_item">Selenium/Serenity</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Kassel</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>> 24 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Refactoring</h5>
        <div><small>Fluggesellschaft (extern)</small></div>

        <div class="p-2">
          <small>
            Umprogrammierung & Generische Globalisierung bestehender Projekte
          </small>
        </div>

        <div class="p-2">
          <span class="tech_item">JavaEE</span>
          <span class="tech_item">Java Generics</span>
          <span class="tech_item">Maven</span>
          <span class="tech_item">git</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Hannover</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>3 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">BigData Archivierung</h5>
        <div><small>Handelsunternehmen (extern)</small></div>

        <div class="p-2">
          <small>
            Konzept, Architektur & Entwicklung einer Serveranwendung in Spring
            Boot zur Konvertierung von Oracle zu einer MongoDB im BigData Umfeld
          </small>
        </div>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Spring Boot</span>
          <span class="tech_item">Oracle</span>
          <span class="tech_item">MongoDB</span>
          <span class="tech_item">Maven</span>
          <span class="tech_item">Docker</span>
          <span class="tech_item">git</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Hamburg</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>3 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Microservices</h5>
        <div><small>Automobilzulieferer (extern)</small></div>

        <div class="p-2">
          <small>
            Agile Entwicklung von Microservices in Spring Boot für ein Webportal
          </small>
        </div>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Spring Boot</span>
          <span class="tech_item">Postgres</span>
          <span class="tech_item">Kafka</span>
          <span class="tech_item">Docker</span>
          <span class="tech_item">Maven</span>
          <span class="tech_item">Jenkins</span>
          <span class="tech_item">git</span>
          <span class="tech_item">Scrum</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Hannover</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>3 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Pipeline Überwachung</h5>
        <div><small>Gastransportunternehmen (extern)</small></div>

        <div class="p-2">
          <small>
            Konzept, Architektur & agile Entwicklung eines Webportals zur
            Überwachung einer Gas Pipeline über Sensordaten
          </small>
        </div>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Spring Boot</span>
          <span class="tech_item">Postgres</span>
          <span class="tech_item">Angular</span>
          <span class="tech_item">Bootstrap</span>
          <span class="tech_item">Docker</span>
          <span class="tech_item">Maven</span>
          <span class="tech_item">Jenkins</span>
          <span class="tech_item">git</span>
          <span class="tech_item">Scrum</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Kassel</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>9 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Android App</h5>
        <div><small>Logistikbranche</small></div>

        <div class="p-2">
          <small>
            Entwicklung einer Android App zur Überwachung der Fahrzeug Flotte
          </small>
        </div>

        <button
          onclick="window.open('https://fleeture.de/produkte/fleeture-mobile-assistant/')"
        >
          Webseite aufrufen
        </button>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Android Studio</span>
          <span class="tech_item">SOAP (hessdroid)</span>
          <span class="tech_item">Gradle</span>
          <span class="tech_item">git</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Braunschweig</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>6 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">
          Windows Appstore Spiel
        </h5>
        <div><small>Startup (extern)</small></div>

        <div class="p-2">
          <small>
            Portierung einer erfolgreichen Android App (Quiz-Spiel) für Windows
            App Store
          </small>
        </div>

        <button
          onclick="window.open('https://www.microsoft.com/de-de/p/wer-wird-reich/9wzdncrfj4p9')"
        >
          Webseite aufrufen
        </button>

        <div class="p-2">
          <span class="tech_item">C#</span>
          <span class="tech_item">.NET</span>
          <span class="tech_item">SQLite</span>
          <span class="tech_item">git</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Wolfenbüttel</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>6 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Logistik Portal</h5>
        <div><small>Logistikbranche</small></div>

        <div class="p-2">
          <small>Entwicklung eines Logistik Portals für den Dekstop</small>
        </div>

        <button
          onclick="window.open('https://fleeture.de/produkte/fleeture-portal/')"
        >
          Webseite aufrufen
        </button>

        <div class="p-2">
          <span class="tech_item">JavaEE</span>
          <span class="tech_item">Eclipse RCP</span>
          <span class="tech_item">Oracle</span>
          <span class="tech_item">Ant</span>
          <span class="tech_item">TeamCity</span>
          <span class="tech_item">git</span>
          <span class="tech_item">SVN</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Braunschweig</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>11 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">Verwaltungssoftware</h5>
        <div><small>Logistikbranche</small></div>

        <div class="p-2">
          <small
            >Konzept, Architektur und Entwicklung einer Desktop
            Verwaltungssoftware mit Hardwareanbindung für Telematik
            Geräte</small
          >
        </div>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">JavaFX</span>
          <span class="tech_item">Oracle</span>
          <span class="tech_item">Maven</span>
          <span class="tech_item">git</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Braunschweig</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>24 Monate</small>
          </div>
        </div>
      </div>

      <!-- CARD -->

      <div class="box mt-4 p-3">
        <h5 class="mb-0 color-black font-weight-bold">
          Visualisierungssoftware
        </h5>
        <div><small>Medizinbranche (extern)</small></div>

        <div class="p-2">
          <small
            >Konzept, Architektur und Entwicklung einer Desktop Anwendung für
            eine Kinderarztpraxis zur Stammdatenverwaltung und visuellen
            Darstellung von Perzentilen (kindliche Entwicklung)</small
          >
        </div>

        <div class="p-2">
          <span class="tech_item">Java</span>
          <span class="tech_item">Swing</span>
          <span class="tech_item">Apache Derby</span>
          <span class="tech_item">JFreeChart</span>
          <span class="tech_item">SVN</span>
        </div>

        <div class="pt-1 pl-2 pr-2 d-flex w-100 justify-content-between">
          <div>
            <b-icon icon="geo-alt" font-scale="1" class="mr-2" />
            <small>Wolfenbüttel</small>
          </div>

          <div>
            <b-icon icon="stopwatch" font-scale="1" class="mr-2" />
            <small>6 Monate</small>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script scoped>
export default {
  methods: {
    closeModal() {
      this.$bvModal.hide("referencesModal");
    },
  },
};
</script>

<style scoped>
.btn_close {
  cursor: pointer;
  padding: 1px;
  text-align: center;
  color: #bf616a;
  margin: auto;
}

.btn_close:hover {
  color: #4c566a;
}

.modal_content {
  color: #4c566a;
}

.banner_img {
  width: 180px;
  border: 0px solid#2e3440;
  filter: drop-shadow(8px 8px 10px #2e3440) opacity(70%) grayscale(30%);
  margin: 0px;
}

button {
  color: #4c566a;
  background-color: inherit;
  font-size: 12px;
  padding: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 200px;
  border: 2px solid#a3be8c;
  font-weight: bold;
}

button:hover {
  color: #eceff4;
  background-color: #bf616a;
  border: 2px solid#bf616a;
}

.box {
  border: 1px solid #d8dee9;
}

.tech_item {
  margin: 4px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  color: #eceff4;
  background-color: #4c566a;
  font-size: 10pt;
  display: inline-block;
  font-weight: bold;
}
</style>
